import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
// pages
import Home from 'pages/Home'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import Terms from 'pages/Terms'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
