import React, { useCallback, memo, useState, useEffect } from 'react'
// libs
import ImageGallery from 'react-image-gallery'
import cx from 'classnames'
import { ToastContainer, toast } from 'react-toastify'
// assets
import { ReactComponent as Logo } from 'assets/SoPlan.svg'
// styles
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import 'styles/app.scss'

const SLIDES = [
  {
    title: 'Something great is \ncoming',
    phoneImgClass: 'phone',
    bgImgClass: 'picture',
  },
  {
    title: 'Next-generation event \nplanning is coming!',
    phoneImgClass: 'phone1',
    bgImgClass: 'picture1',
  },
  {
    title: 'Planning made \nsimple',
    phoneImgClass: 'phone2',
    bgImgClass: 'picture2',
  },
  {
    title: 'Keep your connections \nstrong. Meet now!',
    phoneImgClass: 'phone3',
    bgImgClass: 'picture3',
  },
]

const IMAGES = [
  {
    original: require('assets/image_1.jpg'),
  },
  {
    original: require('assets/image_2.jpg'),
  },
  {
    original: require('assets/image_3.jpg'),
  }, {
    original: require('assets/image_4.jpg'),
  },
]

function App() {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState('')
  const [selectedKey, setSelectedKey] = useState(0)

  useEffect(() => {
    document.title = 'The social event planning app'
  }, [])

  const handleOnChangeSlider = useCallback((index) => {
    setSelectedKey(index)
  }, [])

  const handleOnChange = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  const handleOnChangeInput = useCallback(({ target }) => {
    setValue(target.value.replace('`', ''))
  }, [])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const validateErr = 'Enter a valid e-mail address or mobile phone number. For example: someone@example.com or +12025551234'
    const isPhoneNumber = /^\+[0-9]{6,25}$/.test(value)
    const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value)
    if (!isPhoneNumber && !isEmail) {
      return toast.error(validateErr)
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscribers`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: value.toLowerCase(),
          wantsToJoinBetaTesting: checked,
        })
      })
      if (response.status >= 200 && response.status < 300) {
        toast.success('Thank you for subscribing and trusting us with your contact details. We will keep you informed in the near future!')
        setChecked(false)
        setValue('')
      } else if (response.status === 422) {
        toast.success('Thank you for subscribing and trusting us with your contact details. We will keep you informed in the near future!')
      } else {
        return toast.error('Oops. Something went wrong!')
      }
    } catch (err) {
      return toast.error(err.message)
    }
  }, [checked, value])

  return (
    <div className='container'>
      <ImageGallery
        autoPlay
        showBullets
        showNav={false}
        showThumbnails={false}
        showFullscreenButton={false}
        useBrowserFullscreen={false}
        showPlayButton={false}
        slideInterval={7000}
        items={IMAGES}
        slideDuration={0}
        onBeforeSlide={handleOnChangeSlider}
      />
      <div className='content'>
        <Logo className='logo logo-lg' />
        <div className='title-wrap'>
          {SLIDES.map((i, idx) => (
            <h1 key={`title_${idx}`} className={cx('title', { animate: selectedKey === idx })}>{i.title}</h1>
          ))}
        </div>
        <form className='subscribe-form' onSubmit={handleSubmit}>
          <div className='form-title'>
            <h2>Subscribe to be updated when SoPlan is live</h2>
            <h3>We will not sell or give away your contact details</h3>
          </div>
          <div className='input-wrap'>
            <input
              className='form-input'
              type='text'
              value={value}
              onChange={handleOnChangeInput}
            />
            <div className='placeholder'><span>Enter your e-mail or phone number starting with + and your country code "+31612345678"</span></div>
          </div>
          <button type='submit' className='subscribe-btn' disabled={!value}>Subscribe</button>
          <label className='beta-label' htmlFor='beta-checkbox'>
            <input
              className='beta-checkbox'
              id='beta-checkbox'
              type='checkbox'
              checked={checked}
              onChange={handleOnChange}
            />
            I want to join the beta tester program
          </label>
        </form>
        <div className='phone-img-wrapp'>
          {SLIDES.map((i, idx) => (
            <div
              key={`img_${idx}`}
              className={cx('phone-img', `${i.phoneImgClass}`, { animate: selectedKey === idx })}
            />
          ))}
        </div>

      </div>
      <ToastContainer position='top-right' autoClose={15000} />
    </div>
  )
}

export default memo(App)
