import React, { memo } from 'react'

const PrivacyPolicy = () => (
  <div>
    <h1>Privacy Policy</h1>
    <p>This Privacy Policy applies to all of our Services unless specified otherwise.</p>
    <h2>Information We Collect</h2>
    <p>SoPlan must receive or collect some information to operate, provide, improve, understand, customize, support, and market our Services, including when you install, access, or use our Services. The types of information we receive and collect depend on how you use our Services.</p>
    <p><strong>Information You Provide</strong></p>
    <ul>
      <li><strong>Your Account Information.</strong>&nbsp;You provide your mobile phone number and basic information (including a profile name) to create a SoPlan account. You provide us, all in accordance with applicable laws, the phone numbers in your mobile address book on a regular basis, including those of both the users of our Services and your other contacts. You may provide us an email address. You may also add other information to your account, such as a profile picture and about information.</li>
      <li><strong>Your Events</strong>.&nbsp;We retain your events on our servers and we store them securely. Events are transmitted using an encrypted communication channel. We do encourage you to use a secure network instead of a public network.</li>
      <li><strong>Your Connections</strong>.&nbsp;To help you organize how you meet with others, we may help you identify your contacts who also use SoPlan and the frequency you meet. You may be added to groups but you are in control of who can invite you to groups.</li>
      <li><strong>Customer Support</strong>.&nbsp;You may provide us with information related to your use of our Services, including copies of your events, and how to contact you so we can provide you customer support. For example, you may send us an email with information relating to our app performance or other issues.</li>
    </ul>
    <p><strong>Automatically Collected Information</strong></p>
    <ul>
      <li><strong>Usage And Log Information</strong>.&nbsp;We collect information about your activity on our Services, like service-related, diagnostic, and performance information. This includes information about your activity (including how you use our Services, your Services settings, how you interact with others using our Services, and the time, frequency, and duration of your activities and interactions), log files, and diagnostic, crash, website, and performance logs and reports. This also includes information about when you registered to use our Services, the features you use and how often you meet with other users.</li>
      <li><strong>Device And Connection Information</strong>.&nbsp;We collect device and connection-specific information when you install, access, or use our Services. This includes information like hardware model, operating system information, battery level, signal strength, app version, browser information, and mobile network, connection information including phone number, mobile operator or ISP, language and time zone, and IP, device operations information, and identifiers like device identifiers.</li>
      <li><strong>Location Information</strong>.&nbsp;We may collect device location information if you use our location features, like when you choose to plan an event with users, share your availability with other users and for diagnostics and troubleshooting purposes such as if you are having trouble with our app's location features. We use various technologies to determine location, including IP, GPS, Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers.</li>
      <li><strong>Cookies</strong>.&nbsp;We use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services. For example, we use cookies to provide SoPlan for web and other web-based services. We may also use cookies to understand which of our FAQs are most popular and to show you relevant content related to our Services. Additionally, we may use cookies to remember your choices, like your language preferences, to provide a safer experience, and otherwise to customize our Services for you.&nbsp;</li>
    </ul>
    <p><strong>Third-Party Information</strong></p>
    <ul>
      <li><strong>Information Others Provide About You</strong>.&nbsp;We receive information about you from other users and businesses. For example, when other users or businesses you know use our Services, they may provide your phone number, name, and other information (like information from their mobile address book or in the case of businesses, additional information about you such as unique identifiers), just as you may provide theirs, or they may invite you to an event and invite to a group. We require each of these users and businesses to have lawful rights to collect, use, and share your information before providing any information to us.</li>
      <li><strong>Businesses On SoPlan</strong>.&nbsp;Businesses you interact with using SoPlan provide us information about their interactions with you. A business on SoPlan may also use another company to assist it managing events on behalf of and in support of that business. Please note that when businesses use third-party services, their own terms and privacy policies will govern your use of those services and their use of your information on those services.</li>
      <li><strong>Third-Party Service Providers</strong>.&nbsp;We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with companies to distribute our apps, provide our infrastructure, delivery, and other systems, supply location, map, and places information, process payments, help us understand how people use our Services, market our Services, help you connect with businesses using our Services, conduct surveys and research for us, and help with customer service. These companies may provide us information about you in certain circumstances; for example, app stores may provide us reports to help us diagnose and fix service issues.</li>
    </ul>
    <h2>How We Use Information</h2>
    <p>We use the information we have (subject to choices you make) to operate, provide, improve, understand, customize, support, and market our Services. Here's how:</p>
    <ul>
      <li><strong>Our Services.</strong>&nbsp;We use the information we have to operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services. We understand how people use our Services and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us.</li>
      <li><strong>Safety And Security</strong>.&nbsp;We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally.</li>
      <li><strong>Communications About Our Services</strong>.&nbsp;We use the information we have to communicate with you about our Services and features and let you know about our terms and policies and other important updates. We may provide you marketing for our Services.</li>
      <li><strong>No Third-Party Banner Ads</strong>.&nbsp;We still do not allow third-party banner ads on SoPlan. We have no intention to introduce them, but if we ever do, we will update this policy.</li>
    </ul>
    <h2>Information You And We Share</h2>
    <p>You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.</p>
    <ul>
      <li><strong>Send Your Information To Those You Choose To Communicate With</strong>.&nbsp;You share your information as you use and communicate through our Services.</li>
      <li><strong>Account Information</strong>.&nbsp;Your phone number, profile information and availability may be available to anyone who uses our Services, although you can configure your Services settings to manage certain information available to other users and businesses with whom you communicate.</li>
      <li><strong>Your Contacts And Others</strong>.&nbsp;Users and businesses with whom you communicate may store or reshare your information (including your phone number or messages) with others on and off our Services. You can use your Services settings and the block feature in our Services to manage the users of our Services with whom you communicate and certain information you share.</li>
      <li><strong>Third-Party Service Providers</strong>.&nbsp;We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services.</li>
    </ul>
    <h2>Assignment, Change Of Control, And Transfer</h2>
    <p>All of our rights and obligations under our Privacy Policy are freely assignable by us to any of our affiliates, in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner.</p>
    <h2>Our Legal Bases For Processing Information</h2>
    <p>We&nbsp;collect,&nbsp;use, and&nbsp;share&nbsp;the information we have as described above:</p>
    <ul>
      <li>as necessary to fulfill our&nbsp;Terms;</li>
      <li>consistent with your consent, which you can revoke at any time;</li>
      <li>as necessary to comply with our legal obligations;</li>
      <li>occasionally to protect your vital interests, or those of others;</li>
      <li>as necessary in the public interest; and</li>
      <li>as necessary for our (or others') legitimate interests, including our interests in providing an innovative, relevant, safe, and profitable service to our users and partners, unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data.&nbsp;</li>
    </ul>
    <h2>How You Exercise Your Rights</h2>
    <p>Under the General Data Protection Regulation or other applicable local laws, you have the right to access, rectify, port, and erase your information, as well as the right to restrict and object to certain processing of your information. This includes the right to object to our processing of your information for direct marketing and the right to object to our processing of your information where we are performing a task in the public interest or pursuing our legitimate interests or those of a third party. You can access or port your information using our in-app Request Account Info feature. You can access tools to rectify, update, and erase your information. You can object to processing your information by deleting your account and no longer user our Services.</p>
    <h2>Managing And Deleting Your Information</h2>
    <p>We store information until it is no longer necessary to provide our services, or until your account is deleted, whichever comes first. This is a case-by-case determination that depends on things like the nature of the information, why it is collected and processed, and relevant legal or operational retention needs.</p>
    <p>If you would like to manage, change, limit, or delete your information, we allow you to do that through the following tools:</p>
    <ul>
      <li><strong>Services Settings</strong>.&nbsp;You can change your Services settings to manage certain information available to other users.</li>
      <li><strong>Changing Your Mobile Phone Number, Profile Name And Picture, And About Information</strong>.&nbsp;You must change your mobile phone number using our in-app change number feature and transfer your account to your new mobile phone number. You can also change your profile name, profile picture, and about information at any time.</li>
      <li><strong>Deleting Your Account</strong>.&nbsp;You may delete your account at any time (including if you want to revoke your consent to our use of your information) using our in-app delete my account feature. When you delete your account, your undelivered messages are deleted from our servers as well as any of your other information we no longer need to operate and provide our Services. Be mindful that if you only delete our Services from your device without using our in-app delete my account feature, your information may be stored with us for a longer period. Please remember that when you delete your account, it does not affect the information other users have relating to you, such as their copy of the messages you sent them.</li>
    </ul>
    <h2>Law And Protection</h2>
    <p>We collect, use, preserve, and share your information if we have a good-faith belief that it is reasonably necessary to: (a) respond pursuant to applicable law or regulations, to legal process, or to government requests; (b) enforce our Terms and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, and address fraud and other illegal activity, security, or technical issues; or (d) protect the rights, property, and safety of our users, SoPlan, or others, including to prevent death or imminent bodily harm.</p>
    <h2>Updates To Our Policy</h2>
    <p>We will notify you before we make changes to this Privacy Policy and give you the opportunity to review the revised Privacy Policy before you choose to continue using our Services.</p>
  </div>
)

export default memo(PrivacyPolicy)
